body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #cfe3c4; */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .color-item:hover {
    background-color: #007bff; /* Use your primary color here */
    color: white; /* Change text color for better visibility */
  }
  
  .center-ul {
    margin: 0 auto; /* Set left and right margins to auto for centering */
    text-align: center; /* Reset text alignment if needed */
  }
  
  @keyframes fadeBackground {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  
  /* App.css */
  
  .jumbotron {
    height: 350px;
    background-image: url('/assets/images/bg/bg-image-21.jpg');
    /* background-image: url('') */
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeBackground 10s infinite;
  }
  
  
  
  
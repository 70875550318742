.rn-form-group {
    margin-bottom: 10px;
  }
  
  .rn-form-group input,
  .rn-form-group textarea {
    padding: 5px;
    margin: 0 5px; /* Add 5px left and right margin */
  }

  .formAndStuff {
    padding: 10px 20px;
  }

  .rn-form-group button {
    color: white; /* Set font color to white */
  }

  .custom-input {
    padding: 20px;
    border-radius: 10px;
  }
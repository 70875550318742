.blackjack-game {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
  
  h1 {
    text-align: center;
    color: #f1c40f;
  }
  
  button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  .hands {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .player-hand, .dealer-hand {
    width: 45%;
  }
  
  h2 {
    color: #e74c3c;
  }
  
  .card {
    background-color: white;
    color: #2c3e50;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    display: inline-block;
    width: 100px;
    height: 150px;
    text-align: center;
    font-size: 18px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    position: relative;
  }
  
  .card::before {
    content: attr(data-value);
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 24px;
  }
  
  .card::after {
    content: attr(data-suit);
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 24px;
  }
  
  .card.back {
    background-color: #3498db;
    color: white;
    font-size: 36px;
    line-height: 150px;
  }
  
  .actions {
    text-align: center;
    margin-top: 20px;
  }
  
  .message {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    color: #f1c40f;
  }
.image-container {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
  }
  
  .about-image {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 992px) {
    .about-image {
      display: none !important;
    }
  }

  .thumbnail img {
    border-radius: 20px;
  }
  

  